import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from './oauth/oauth.service';
import { OAuthUtil } from 'src/utils/oauth-util';
import { StorageService } from './oauth/storage.service';
import { FlashMessageService } from './shared/views/flash-message/flash-message.service';
import { User } from 'src/models/user';
import { Cookie } from 'src/constants/storage';
import { CookiesBannerComponent } from './shared/views/cookie-banner/cookie-banner.component';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'gl-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(CookiesBannerComponent, { static: true }) cookiesBanner: CookiesBannerComponent;

  private _sub: any;
  private _isBadge = true;
  private _showNavbar: boolean;
  private _showBackground: boolean;
  private _user: User;
  private _userReady = false;

  constructor(private _oauthService: OAuthService,
    private _storageService: StorageService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _flashMessageService: FlashMessageService,
    private _translate: TranslateService) {
    this.checkLanguage();
  }

  ngOnInit() {
    // check if user is logged in
    if (OAuthUtil.isLoggedInInBrowser(this._storageService)) {
      this._oauthService.getUserBrief().subscribe(
        user => {
          console.log("Logged in user:", user);

          this.processingUser(user)
        },
        error => this._flashMessageService.showErrorMessage(error));
    } else {
      this._userReady = true;
      this._oauthService.getAnonymousToken().subscribe();
    }
    this._sub = this._route.queryParams.subscribe(params => {
      const nav = +params['nav'];
      this._isBadge = nav === 0;
    });
    this._oauthService.user$.subscribe(user => {
      this._user = user;
      // We hide the banner once the user logged in
      if (this.cookiesBanner.visible()) {
        this.cookiesBanner.hideBanner();
      }
    });

    // Check if router has history
    /*
    this._router.events.filter(e => e instanceof NavigationEnd).pairwise().subscribe(e => {
      if (e.length > 0) {
        const previousRoute = e[0];
        if (previousRoute instanceof NavigationEnd) {
          if (previousRoute.url === '/account/sign-in') {
            this._storageService.remove(LocalStorage.hasHistory);
          } else {
            this._storageService.set(LocalStorage.hasHistory, LocalStorage.hasHistoryInTrue);
          }
        }
      } else {
        this._storageService.remove(LocalStorage.hasHistory);
      }
    });
    */

    // this._router.events.subscribe(() => this.checkRoute());
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        let currentRoute = this._route.root;
        while (currentRoute.children[0] !== undefined) {
          currentRoute = currentRoute.children[0];
        }
        const navbar = currentRoute.snapshot.data['navbar'];
        this._showNavbar = navbar !== undefined ? navbar : true;
        const background = currentRoute.snapshot.data['background'];
        this._showBackground = background !== undefined ? background : false; // DEMO APP
      });

    // Check if cookie consent banner was shown
    if (!this._storageService.getLocalStorage(Cookie.cookieBannerSeen)) {
      this.cookiesBanner.showBanner();
    }
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  checkLanguage() {
    this._translate.setDefaultLang('en');
    const localeCookie = this._storageService.get(Cookie.locale);
    if (localeCookie) {
      this._translate.use(localeCookie);
    } else {
      const userLang = navigator.language.slice(0, 2);
      if (userLang === 'en' || userLang === 'de' || userLang === 'cn' || userLang === 'es') {
        this._translate.use(userLang);
      }
      this._storageService.set(Cookie.locale, this._translate.currentLang);
    }
  }

  private processingUser(user: User) {
    this._userReady = true;
    if (this._oauthService.checkLoggedInUserProfileStatus(user)) {
      // For Development, show ChangeLog
      // this.changelogForm.showModal(null);
    }
  }
}
